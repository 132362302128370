import { Component, effect, input, signal } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'squadbox-document-viewer-content',
  imports: [NgxExtendedPdfViewerModule, fromSeriousSystem.SpinnerDirective],
  template: `
    <!-- Spinner -->
    <div
      class="absolute inset-0 z-10 flex items-center justify-center"
      [class.hidden]="!isLoading()"
    >
      <div sdSpinner size="md"></div>
    </div>
    <!-- PDF Viewer -->
    <ngx-extended-pdf-viewer
      [src]="src()"
      [enableDragAndDrop]="false"
      [showToolbar]="false"
      [sidebarVisible]="true"
      [showBorders]="false"
      [customSidebar]="customSidebar"
      (pdfLoadingStarts)="handlePdfLoadingStarts()"
      (pdfLoaded)="handlePdfLoaded()"
    ></ngx-extended-pdf-viewer>
    <!-- PDF Viewer: Sidebar -->
    <ng-template #customSidebar>
      <pdf-sidebar-content id="sidebarContainer"></pdf-sidebar-content>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply relative w-full h-full;
        @apply typo-h1 bg-neutral-100;
        @apply flex items-center justify-center;

        ::ng-deep {
          ngx-extended-pdf-viewer {
            @apply w-full h-full;

            #mainContainer,
            #sidebarContainer,
            #sidebarContent,
            #viewerContainer {
              @apply bg-neutral-100 !important;
            }

            #thumbnailView,
            #viewerContainer {
              @apply hide-scrollbar !important;
            }

            #sidebarContainer {
              top: -1px;
            }

            #viewerContainer .page {
              @apply first:mt-11;
            }
          }
        }
      }
    `,
  ],
})
export class DocumentViewerContentComponent {
  public readonly src = input.required<string>();

  public isLoading = signal(true);

  constructor() {
    effect(() => {
      if (this.src()) {
        this.isLoading.set(true);
      }
    });
  }

  public handlePdfLoaded() {
    this.isLoading.set(false);
  }

  public handlePdfLoadingStarts() {
    this.isLoading.set(true);
  }
}
