import { Component, effect, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import * as fromGenerated from '../../../_generated';
import * as fromLayout from '../../../layout';
import { fromFileUploadHelpers } from '../../assistants';
import * as fromShared from '../../shared';
import * as fromChats from '../../store/chats.reducer';
import { chatsFeature } from '../../store/chats.reducer';
import { DocumentViewerContentComponent } from './document-viewer-content.component';
import { DocumentViewerHeaderComponent } from './document-viewer-header.component';

@Component({
  selector: 'squadbox-document-viewer-smart',
  imports: [
    DocumentViewerHeaderComponent,
    DocumentViewerContentComponent,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  template: `
    <div class="hide-scrollbar w-full h-screen overflow-y-none opacity-100">
      @if (selectedDocumentChunksForSelectedDocument(); as documentWithChunk) {
      <squadbox-document-viewer-header
        [title]="documentWithChunk.document.filename | filenameWithoutExtension"
        [fileType]="
          fromFileUploadHelpers.getFileType(
            documentWithChunk.document.extension
          )
        "
        (closeClicked)="handleDocumentViewerHeaderCloseClicked()"
      ></squadbox-document-viewer-header>
      <squadbox-document-viewer-content
        [src]="presignedUrl()"
      ></squadbox-document-viewer-content>
      }
    </div>
  `,
  styles: [``],
})
export class DocumentViewerSmartComponent {
  public readonly fromFileUploadHelpers = fromFileUploadHelpers;
  private readonly chatsStore = inject<Store<fromChats.ChatsState>>(Store);
  private readonly layoutStore = inject<Store<fromLayout.LayoutState>>(Store);
  private readonly documentsService = inject(fromGenerated.DocumentsService);

  public readonly selectedDocumentChunksForSelectedDocument =
    this.chatsStore.selectSignal(
      chatsFeature.selectSelectedDocumentWithChunksForSelectedDocument
    );

  public readonly presignedUrl = signal<string>('');

  constructor() {
    effect(() => {
      const documentUuid =
        this.selectedDocumentChunksForSelectedDocument()?.document?.uuid;

      if (documentUuid) {
        this.documentsService
          .documentsControllerGeneratePresignedUrl(documentUuid)
          .pipe(
            tap((presignedUrl) => {
              this.presignedUrl.set(presignedUrl.url);
            })
          )
          .subscribe();
      }
    });
  }

  public handleDocumentViewerHeaderCloseClicked() {
    this.layoutStore.dispatch(
      fromLayout.LayoutActions.setViewMode({
        viewMode: fromLayout.ViewMode.CONVERSATION,
      })
    );
  }
}
